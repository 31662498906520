<template>
	<div id="app">

		<header class="shadow" style="background: #928b82;">
			<div class="container mx-auto px-4 py-6 flex justify-between items-center">
				<h1 class="text-md font-bold text-white">TRIS3 HOTEL</h1>
				<nav>
					<ul class="flex space-x-6">
						<li><a href="#rooms" class="text-white hover:text-gray-900">Rooms</a></li>
						<li><a href="#amenities" class="text-white hover:text-gray-900">Amenities</a></li>
						<li><a href="#contact" class="text-white hover:text-gray-900">Contact</a></li>
					</ul>
				</nav>
			</div>
		</header>


		<!-- Hero Section -->
		<section class="bg-cover bg-center h-screen" id="hero-slider" 
			:style="{ backgroundImage: 'url(images/photos/' + background_images[background_image_index] + ')' }">
			<div class="bg-black bg-opacity-30 h-full flex items-center justify-center">
				<div class="text-center text-white px-6">
					<h2 class="text-4xl font-bold mb-4">Welcome to Tris3 Hotel</h2>
					<p class="text-lg mb-6">Experience the luxury and comfort.</p>
					<a href="#rooms" class="bg-blue-500 text-white px-6 py-3 rounded-lg">Book Now</a>
					<div class="flex justify-between gap-1 mt-20">
						<!-- <div v-for="i in background_images.length" :key="i"  -->
						<!-- class="h-3 w-3 rounded-full bg-black block" :class="{ 'bg-white': i === background_image_index }"> -->
						<!-- </div> -->
					</div>
				</div>
			</div>
		</section>

		<!-- Amenities Section -->
		<section class="py-12" id="amenities">
			<div class="container mx-auto px-4 rounded-lg">
				<h2 class="text-3xl font-bold text-center mb-8 headers-color">Amenities</h2>
				<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					<div class="bg-white shadow-lg p-6" 
						v-for="amenity in amenities" :key="amenity.id">
						<img v-if="amenity.image" 
							:src="getImageUrl(amenity.image)" :alt="amenity.name" class="w-full h-48 object-cover">
						<div class="h-48 block" v-else></div>
						<h3 class="text-2xl font-bold mb-2">{{amenity.name}}</h3>
						<p class="text-gray-700 mb-4">{{amenity.description}}</p>
					</div>
				</div>
			</div>
		</section>

		<!-- Rooms Section -->
		<section class="py-12" id="rooms">
			<div class="container mx-auto px-4">
				<h2 class="text-3xl font-bold text-center mb-8 headers-color">Our Rooms</h2>
				<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
					<div v-for="room in rooms" :key="room.id" class="bg-white shadow-lg overflow-hidden">
						<img :src="getImageUrl(room.img)" :alt="room.name" class="w-full h-64 object-cover">
						<div class="p-6">
							<h3 class="text-2xl font-bold mb-2">{{ room.type }}</h3>
							<p class="text-gray-700 mb-4" v-html="room.description"></p>
							<button @click="showRoomDetails(room.id)" class="bg-blue-500 text-white px-4 py-2 rounded-lg">View
								Details</button>
						</div>
					</div>
				</div>
				<!-- Room details  and reservation-->
				<div class="bg-white shadow overflow-hidden mt-8" v-if="currentOpenRoom" id="room-details">
					<img :src="getImageUrl(currentOpenRoom.img)" :alt="currentOpenRoom.name" class="w-full h-48 object-cover">
					<div class="p-6">
						<h3 class="text-2xl font-bold mb-2">{{ currentOpenRoom.type }}</h3>
						<p class="text-gray-700 mb-4" v-html="currentOpenRoom.description"></p>
						<a href="https://www.booking.com/hotel/al/triss3.html" class="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
							@click="bookRoom(currentOpenRoom.id)">Book Now</a>
						<!-- <form class="grid grid-cols-1 gap-4 p-4 m-4" v-if="showBookingForm" id="room-book"> -->
						<!---->
						<!-- 	<h3 class="text-2xl font-bold mb-2">{{ currentOpenRoom.type }} Reservation</h3> -->
						<!-- 	<input v-model="bookingDetails.guest_name" type="text" name="guest_name" placeholder="Your Name" -->
						<!-- 		class="p-4 rounded-lg"> -->
						<!-- 	<div class="flex gap-2"> -->
						<!-- 		<input v-model="bookingDetails.check_in_date" type="date" name="check_in_date" -->
						<!-- 			class="p-4 rounded-lg flex-1"> -->
						<!-- 		<input v-model="bookingDetails.check_out_date" type="date" name="check_out_date" -->
						<!-- 			class="p-4 rounded-lg flex-1"> -->
						<!-- 	</div> -->
						<!-- 	<div class="flex gap-2"> -->
						<!-- 		<input v-model="bookingDetails.phone" type="text" name="phone" placeholder="Your Phone" -->
						<!-- 			class="p-4 rounded-lg flex-1"> -->
						<!-- 		<input v-model="bookingDetails.email" type="email" name="email" placeholder="Your Email" -->
						<!-- 			class="p-4 rounded-lg flex-1"> -->
						<!-- 	</div> -->
						<!-- 	<textarea v-model="bookingDetails.message" name="message" rows="4" placeholder="Additional Information" -->
						<!-- 		class="p-4 rounded-lg"></textarea> -->
						<!-- 	<div class="flex"> -->
						<!-- 		<div class="flex-1"> -->
						<!-- 			<input type="checkbox" name="terms" id="terms" class="mr-2" v-model="bookingDetails.terms"> -->
						<!-- 			<label for="terms">I agree to the terms and conditions</label> -->
						<!-- 		</div> -->
						<!-- 		<vue-recaptcha v-show="bookingDetails.terms" :sitekey="key" size="normal" theme="dark" :hl="lang" -->
						<!-- 			:loading-timeout="loadingTimeout" @verify="recaptchaVerified('reserve')" @expire="recaptchaExpired('reserve')" -->
						<!-- 			@fail="recaptchaFailed" @error="recaptchaError"> -->
						<!-- 		</vue-recaptcha> -->
						<!-- 		<button type="button" @click="reserveRoom(currentOpenRoom.id)" v-if="bookingDetails.terms && notRobot['can_reserve']" -->
						<!-- 			class="bg-blue-800 text-white px-4 py-2 rounded-lg">Make reservation</button> -->
						<!-- 	</div> -->
						<!-- </form> -->
					</div>
				</div>
			</div>
		</section>

		<!-- Contact Section -->
		<section class="py-12" id="contact">
			<div class="container mx-auto px-4">
				<h2 class="text-3xl font-bold text-center mb-8 headers-color">Contact Us</h2>
				<div class="grid grid-cols-1 md:grid-cols-2 gap-8">
					<div>
						<h3 class="text-2xl font-bold mb-2">Tris3 Hotel</h3>
						<p class="text-gray-700 mb-4">Radhime, Vlorë, Albania 9426</p>
						<p class="text-gray-700 mb-4">Phone: +355 69 294 5222</p>
						<p class="text-gray-700 mb-4">Email: <a href="mailto:tris3hotel@gmail.com"
								class="text-blue-500">tris3hotel@gmail.com</a></p>
						<!-- location map with 40.351722, 19.486018 coordinates --> 
						<iframe class="w-full"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.073073073073!2d19.48382931572968!3d40.351722079379674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135a0b1b0b1b1b1b%3A0x1b1b1b1b1b1b1b1b!2sTris3%20Hotel!5e0!3m2!1sen!2s!4v1629783660006!5m2!1sen!2s"
							width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
					</div>
					<div>
						<form action="#" method="POST" class="grid grid-cols-1 gap-4">
							<input type="text" v-model="email.name" placeholder="Your Name" class="p-4 rounded-lg">
							<!-- <input type="email" v-model="email.email" placeholder="Your Email" class="p-4 rounded-lg"> -->
							<textarea name="message" rows="4" placeholder="Your Message" class="p-4 rounded-lg" v-model="email.message"></textarea>
							<div class="flex justify-between">
								<vue-recaptcha v-show="email.name != ''" :sitekey="key" size="normal" theme="dark" :hl="lang"
									:loading-timeout="loadingTimeout" @verify="recaptchaVerified('contact')" @expire="recaptchaExpired('contact')"
									@fail="recaptchaFailed" @error="recaptchaError">
								</vue-recaptcha>
								<a :href="'mailto:tris3hotel@gmail.com?subject=' + email.name + '&body=' + email.message" 
									class="bg-blue-500 text-white px-4 py-2 rounded-lg" v-if="notRobot['can_contact']">Send Message</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>

		<!-- Footer -->
		<footer class="text-white py-6" style="background-color: #928b82">
			<div class="container mx-auto px-4 text-center">
				<p>&copy; 2024 Tris3 Hotel. All rights reserved.</p>
			</div>
		</footer>

	</div>
</template>

<script>
import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';

// import VueRecaptcha from 'vue-recaptcha'; 
export default {
			components: {
				vueRecaptcha
			},
			data() { 
				return {
					background_image_index: 0,
					background_images: [
						'2B6A3998.jpg', '2B6A3667.JPG', '2B6A3668.JPG', '2B6A3670.JPG', '2B6A3671.JPG', '2B6A3654.JPG', '2B6A3656.JPG', '2B6A3657.JPG',
						'2B6A3658.JPG', '2B6A3659.JPG', '2B6A3660.JPG', '2B6A3661.JPG', '2B6A3662.JPG', '2B6A3663.JPG', '2B6A3664.JPG', '2B6A3665.JPG',
						'2B6A3666.JPG', '2B6A3667.JPG', '2B6A3668.JPG', '2B6A3670.JPG', '2B6A3671.JPG', '2B6A3672.JPG', '2B6A3673.JPG', '2B6A3675.JPG',
						'2B6A3676.JPG', '2B6A3677.JPG', '2B6A3678.JPG', '2B6A3679.JPG', '2B6A3680.JPG', '2B6A3682.JPG', '2B6A3683.JPG', '2B6A3684.JPG',
						'2B6A3686.JPG', '2B6A3687.JPG', '2B6A3688.JPG', '2B6A3690.JPG', '2B6A3691.JPG', '2B6A3692.JPG', '2B6A3693.JPG', '2B6A3694.JPG',
						'2B6A3695.JPG', '2B6A3696.JPG', '2B6A3697.JPG', '2B6A3698.JPG', '2B6A3699.JPG', '2B6A3700.JPG', '2B6A3706.JPG', '2B6A3707.JPG',
						'2B6A3708.JPG', '2B6A3709.JPG', '2B6A3710.JPG', '2B6A3711.JPG', '2B6A3712.JPG', '2B6A3713.JPG', '2B6A3714.JPG', '2B6A3715.JPG',
						'2B6A3716.JPG', '2B6A3717.JPG', '2B6A3718.JPG', '2B6A3719.JPG', '2B6A3720.JPG', '2B6A3721.JPG', '2B6A3722.JPG', '2B6A3723.JPG',
						'2B6A3724.JPG', '2B6A3725.JPG', '2B6A3726.JPG', '2B6A3727.JPG', '2B6A3728.JPG', '2B6A3729.JPG', '2B6A3731.JPG', '2B6A3732.JPG',
						'2B6A3733.JPG', '2B6A3734.JPG', '2B6A3735.JPG', '2B6A3736.JPG', '2B6A3738.JPG', '2B6A3739.JPG', '2B6A3740.JPG', '2B6A3742.JPG',
						'2B6A3743.JPG', '2B6A3744.JPG', '2B6A3745.JPG', '2B6A3746.JPG', '2B6A3748.JPG', '2B6A3749.JPG', '2B6A3750.JPG', '2B6A3752.JPG',
						'2B6A3753.JPG', '2B6A3754.JPG', '2B6A3755.JPG', '2B6A3756.JPG', '2B6A3757.JPG', '2B6A3758.JPG', '2B6A3759.JPG', '2B6A3760.JPG',
						'2B6A3762.JPG', '2B6A3764.JPG', '2B6A3765.JPG', '2B6A3768.JPG', '2B6A3769.JPG', '2B6A3772.JPG', '2B6A3774.JPG', '2B6A3775.JPG',
						'2B6A3776.JPG', '2B6A3777.JPG', '2B6A3778.JPG', '2B6A3779.JPG', '2B6A3780.JPG', '2B6A3781.JPG', '2B6A3782.JPG', '2B6A3783.JPG',
						'2B6A3784.JPG', '2B6A3785.JPG', '2B6A3786.JPG', '2B6A3787.JPG', '2B6A3788.JPG', '2B6A3789.JPG', '2B6A3790.JPG', '2B6A3792.JPG',
						'2B6A3793.JPG', '2B6A3794.JPG', '2B6A3795.JPG', '2B6A3796.JPG', '2B6A3797.JPG', '2B6A3798.JPG', '2B6A3799.JPG', '2B6A3801.JPG',
						'2B6A3805.JPG', '2B6A3806.JPG', '2B6A3808.JPG', '2B6A3810.JPG', '2B6A3812.JPG', '2B6A3815.JPG', '2B6A3816.JPG', '2B6A3817.JPG',
						'2B6A3818.JPG', '2B6A3819.JPG', '2B6A3820.JPG', '2B6A3821.JPG', '2B6A3822.JPG', '2B6A3823.JPG', '2B6A3824.JPG', '2B6A3825.JPG',
						'2B6A3826.JPG', '2B6A3827.JPG', '2B6A3828.JPG', '2B6A3829.JPG', '2B6A3832.JPG', '2B6A3833.JPG', '2B6A3835.JPG', '2B6A3836.JPG',
						'2B6A3837.JPG', '2B6A3838.JPG', '2B6A3839.JPG', '2B6A3840.JPG', '2B6A3842.JPG', '2B6A3844.JPG', '2B6A3846.JPG', '2B6A3847.JPG',
						'2B6A3848.JPG', '2B6A3849.JPG', '2B6A3850.JPG', '2B6A3851.JPG', '2B6A3852.JPG', '2B6A3853.JPG', '2B6A3854.JPG', '2B6A3856.JPG', 
						'2B6A3857.JPG', '2B6A3858.JPG', '2B6A3859.JPG', '2B6A3860.JPG', '2B6A3863.JPG', '2B6A3864.JPG', '2B6A3865.JPG', '2B6A3866.JPG',
						'2B6A3867.JPG', '2B6A3869.JPG', '2B6A3870.JPG', '2B6A3873.JPG', '2B6A3874.JPG', '2B6A3876.JPG', '2B6A3877.JPG', '2B6A3880.JPG',
						'2B6A3881.JPG', '2B6A3883.JPG', '2B6A3884.JPG', '2B6A3887.JPG', '2B6A3889.JPG', '2B6A3890.JPG', '2B6A3891.JPG', '2B6A3892.JPG',
						'2B6A3893.JPG', '2B6A3894.JPG', '2B6A3895.JPG', '2B6A3896.JPG', '2B6A3898.JPG', '2B6A3899.JPG', '2B6A3900.JPG', '2B6A3903.JPG',
						'2B6A3904.JPG', '2B6A3908.JPG', '2B6A3912.JPG', '2B6A3914.JPG', '2B6A3918.JPG', '2B6A3920.JPG', '2B6A3921.JPG', '2B6A3929.JPG',
						'2B6A3930.JPG', '2B6A3931.JPG', '2B6A3932.JPG', '2B6A3934.JPG', '2B6A3936.JPG', '2B6A3937.JPG', '2B6A3938.JPG', '2B6A3939.JPG',
						'2B6A3940.JPG', '2B6A3941.JPG', '2B6A3942.JPG', '2B6A3943.JPG', '2B6A3944.JPG', '2B6A3945.JPG', '2B6A3946.JPG', '2B6A3947.JPG',
						'2B6A3948.JPG', '2B6A3949.JPG', '2B6A3950.JPG', '2B6A3951.JPG', '2B6A3952.JPG', '2B6A3954.JPG', '2B6A3955.JPG', '2B6A3956.JPG',
						'2B6A3957.JPG', '2B6A3958.JPG', '2B6A3959.JPG', '2B6A3960.JPG', '2B6A3961.JPG', '2B6A3962.JPG', '2B6A3963.JPG', '2B6A3964.JPG',
						'2B6A3965.JPG', '2B6A3966.JPG', '2B6A3967.JPG', '2B6A3968.JPG', '2B6A3969.JPG', '2B6A3970.JPG', '2B6A3971.JPG', '2B6A3972.JPG',
						'2B6A3974.JPG', '2B6A3976.JPG', '2B6A3977.JPG', '2B6A3978.JPG', '2B6A3979.JPG', '2B6A3982.JPG', '2B6A3983.JPG', '2B6A3984.JPG',
						'2B6A3985.JPG', '2B6A3986.JPG', '2B6A3987.JPG', '2B6A3988.JPG', '2B6A3989.JPG', '2B6A3990.JPG', '2B6A3991.JPG', '2B6A3992.JPG', 
						'2B6A3993.JPG', '2B6A3994.JPG', '2B6A3995.JPG', '2B6A3996.JPG', '2B6A3997.JPG', '2B6A3998.JPG', '2B6A3999.JPG', '2B6A4000.JPG',
						'2B6A4001.JPG', '2B6A4002.JPG', '2B6A4004.JPG', '2B6A4005.JPG', '2B6A4006.JPG', '2B6A4007.JPG', '2B6A4010.JPG', '2B6A4011.JPG',
						'2B6A4013.JPG', '2B6A4014.JPG', '2B6A4015.JPG', '2B6A4016.JPG', '2B6A4017.JPG', '2B6A4018.JPG', '2B6A4019.JPG', '2B6A4020.JPG',
						'2B6A4023.JPG', '2B6A4026.JPG', '2B6A4027.JPG', '2B6A4028.JPG', '2B6A4029.JPG', '2B6A4031.JPG', '2B6A4032.JPG', '2B6A4034.JPG',
						'2B6A4035.JPG', '2B6A4037.JPG', '2B6A4038.JPG', '2B6A4039.JPG', '2B6A4042.JPG', '2B6A4043.JPG', '2B6A3998.jpg', 
					],
					recaptcha: null,
					loadingTimeout: 500, 
					key: '6Lf0ecMpAAAAAH2pDGp0snR_fVCk6R-_HVf-BVCP', 
					backend: 'https://api.tris3hotel.al',
					hotel: 1,
					rooms: [],
					amenities: [],
					currentOpenRoom: null,
					showBookingForm: false,
					bookingDetails: {
						guest_name: '',
						check_in_date: /* today's date */ new Date().toISOString().split('T')[0],
						check_out_date: '',
						// phone or email contact details can be added here
						phone: '',
						email: '',
						terms: false,
						//text area for additional information 
						message: ''
					}, 
					email: {
						name: '',
						email: '',
						message: ''
					},
					lang: '',	
					notRobot: {
						can_reserve: false,
						can_contact: false
					}
				};
			},
			// components: { VueRecaptcha },
			methods: {
				recaptchaVerified(response) {
					console.log('Recaptcha verified:', response);
					this.notRobot['can_' + response] = true;
				},
				recaptchaExpired(action) {
					console.log('Recaptcha expired');
					this.notRobot['can_' + action] = false;
				},
				recaptchaFailed() {
					console.log('Recaptcha failed');
				},
				recaptchaError(error) {
					console.error('Recaptcha error:', error);
				},	

					
				async fetchRooms() {
					try {
						const response = await axios.get(this.backend + '/hotels/' + this.hotel + '/rooms');
						this.rooms = response.data;
						this.currentOpenRoom = this.rooms[0];
					} catch (error) {
						console.error('Error fetching rooms:', error);
					}
				},
				async getAmenities() {
					try {
						const response = await axios.get(this.backend + '/hotels/' + this.hotel + '/amenities');
						this.amenities = response.data;
					} catch (error) {
						console.error('Error fetching amenities:', error);
					}
				},

				getImageUrl(imageName) {
					return `/images/photos/${imageName}`;
				},
				showRoomDetails(roomId) {
					this.currentOpenRoom = this.rooms.find(room => room.id === roomId);
					window.location.hash = '#room-details';
				},
				bookRoom(roomId) {
					this.showBookingForm = true;
					this.currentOpenRoom = this.rooms.find(room => room.id === roomId);
				},
				async reserveRoom(roomId) {
					const bookingPayload = JSON.stringify({
						...this.bookingDetails,
						room_id: roomId,
						guest_name: this.bookingDetails.guest_name,
						check_in_date: this.bookingDetails.check_in_date,
						type_id: this.currentOpenRoom.type_id,
						check_out_date: this.bookingDetails.check_out_date
					});
					try {
						await axios.post(this.backend + '/hotels/' + this.hotel + '/rooms/' + roomId + '/reserve', bookingPayload);
						alert('Room booked successfully!');
						this.showBookingForm = false;
						this.bookingDetails = {
							guest_name: '',
							check_in_date: new Date().toISOString().split('T')[0],
							check_out_date: '',
							phone: '',
							email: '',
							terms: false,
							message: ''
						};
					} catch (error) {
						console.error('Error booking room:', error);
					}
				},
				setBackgroundImage() {
					const randomIndex = Math.floor(Math.random() * this.background_images.length);
					this.background_image_index = randomIndex;
				}
			},
			mounted() {
				this.fetchRooms();
				this.getAmenities();
				this.lang = document.documentElement.lang || 'en';

				// set background image every 5 seconds 
				setInterval(() => {
					this.setBackgroundImage();
				}, 5000);

			}
		}




</script>

<style>

		/* Smooth scrolling */
		html {
			scroll-behavior: smooth;
		}

		.bg-blue-500 {
			/* background-color: #b14d4d; */
			background-color: rgb(107 98 101);
		}

		.headers-color {
			color: #c39999;
		}

		.h-48 {
			height: 24rem;
		}

		#room-book input,
		#room-book textarea {
			padding: 10px;
			border-radius: 5px;
			border: 1px solid #ccc;
			margin-bottom: 10px;
		}
		#contact input,
		#contact textarea {
			padding: 10px;
			border-radius: 2px;
			border: 1px solid #ccc;
			margin-bottom: 10px;
		}
		/* animate hero-slider background-image changed */ 
		#hero-slider {
			transition: background-image 1s ease-in-out;
		}
	</style>
